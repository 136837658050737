import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
import aljieudumMap from './images/aljieudum.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Map = makeShortcode("Map");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aljiuedum is a sovereign island country off the North-Western coast of mainland `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{`.`}</p>

    <Map src={aljieudumMap} width="400px" align="right" mdxType="Map" />
    <h2>{`Geography`}</h2>
    <p>{`Aljieudum is located on the southern Peninsula of `}<a parentName="p" {...{
        "href": "/Edelun",
        "title": "Edelun"
      }}>{`Edelun`}</a>{`, a large island off the North-West coast of `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{`, neighbored by `}<a parentName="p" {...{
        "href": "/Esharn",
        "title": "Esharn"
      }}>{`Esharn`}</a>{` and `}<a parentName="p" {...{
        "href": "/Volkharn",
        "title": "Volkharn"
      }}>{`Volkharn`}</a>{`. The `}<a parentName="p" {...{
        "href": "/Sejun%20Sea",
        "title": "Sejun Sea"
      }}>{`Sejun`}</a>{` and `}<a parentName="p" {...{
        "href": "/Haskan%20Sea",
        "title": "Haskan Sea"
      }}>{`Haskan`}</a>{` Seas border Aljieudum's Western and South-Eastern coastlines.`}</p>
    <h2>{`Major Cities`}</h2>
    <p>{`The Capital and largest city in Aljieudum is `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`, home to `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia Omnia`}</a>{`, the largest University for learning `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{` in the world.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      